export const FeatureLoginPage = {
  ActionSignUpCapitalized: 'Action.SignUpCapitalized',
  ActionLogInCapitalized: 'Action.LogInCapitalized',
  ActionDeviceCode: 'Action.DeviceCode',
  ActionResendEmail: 'Action.ResendEmail',
  ActionSendVerificationEmail: 'Action.SendVerificationEmail',
  ActionOk: 'Action.Ok',
  ActionAnotherLoggedInDevice: 'Action.AnotherLoggedInDevice',
  ActionForgotPasswordOrUsernameQuestionCapitalized:
    'Action.ForgotPasswordOrUsernameQuestionCapitalized',
  DescriptionCurfewMessage: 'Description.CurfewMessage',

  HeadingLoginRoblox: 'Heading.LoginRoblox',

  LabelNoAccount: 'Label.NoAccount',
  LabelPassword: 'Label.Password',
  LabelUsername: 'Label.Username',
  LabelUsernameEmailPhone: 'Label.UsernameEmailPhone',
  LabelLoginWithYour: 'Label.LoginWithYour',
  LabelUnverifiedEmailInstructions: 'Label.UnverifiedEmailInstructions',
  LabelNotReceived: 'Label.NotReceived',
  LabelVerificationEmailSent: 'Label.VerificationEmailSent',
  LabelEmailNeedsVerification: 'Label.EmailNeedsVerification',

  MessageUnknownErrorTryAgain: 'Message.UnknownErrorTryAgain',
  MessageUsernameAndPasswordRequired: 'Message.UsernameAndPasswordRequired',

  ResponseAccountNotFound: 'Response.AccountNotFound',
  ResponseAccountIssueErrorContactSupport: 'Response.AccountIssueErrorContactSupport',
  ResponseTooManyAttemptsPleaseWait: 'TooManyAttemptsPleaseWait',
  ResponseCaptchaErrorFailedToLoad: 'Response.CaptchaErrorFailedToLoad',
  ResponseCaptchaErrorFailedToVerify: 'Response.CaptchaErrorFailedToVerify',
  ResponseGlobalAppAccessError: 'Response.GlobalAppAccessError',
  ResponseIncorrectEmailOrPassword: 'Response.IncorrectEmailOrPassword',
  ResponseIncorrectPhoneOrPassword: 'Response.IncorrectPhoneOrPassword',
  ResponseIncorrectUsernamePassword: 'Response.IncorrectUsernamePassword',
  ResponseEmailLinkedToMultipleAccountsLoginWithUsername:
    'Response.EmailLinkedToMultipleAccountsLoginWithUsername',
  ResponseLoginWithUsername: 'Response.LoginWithUsername',
  ResponseUnverifiedEmailLoginWithUsername: 'Response.UnverifiedEmailLoginWithUsername',
  ResponseUnverifiedPhoneLoginWithUsername: 'Response.UnverifiedPhoneLoginWithUsername',
  ResponseVerificationError: 'Response.VerificationError'
};

export default {
  FeatureLoginPage
};
