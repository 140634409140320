/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';

// constants
import { FeatureLoginPage } from '../../common/constants/translationConstants';
import { errorCodes, retryAttempts, eventCounters } from '../constants/loginConstants';
import { loginTranslationConfig } from '../translation.config';
import {
  TCaptchaInputParams,
  TOnCaptchaChallengeCompletedData,
  TOnCaptchaChallengeInvalidatedData
} from '../../common/types/captchaTypes';
import {
  T2svChallengeInputParams,
  TOn2svChallengeCompletedData,
  TOn2svChallengeInvalidatedData
} from '../../common/types/twoStepVerificationTypes';
import {
  TSecurityQuestionsInputParam,
  TOnSecurityQuestionsChallengeCompletedData,
  TOnSecurityQuestionsChallengeInvalidatedData
} from '../../common/types/securityQuestionsTypes';
import {
  TLoginParams,
  CredentialType,
  TLoginResponse,
  TLoginWithVerificationTokenResponse
} from '../../common/types/loginTypes';

import { TLoginWithAuthTokenParams } from '../../common/types/crossDeviceLoginTypes';

// services
import { login, loginWithVerificationToken } from '../services/loginService';
import { sendLoginButtonClickEvent } from '../services/eventService';

// utils
import { navigateToPage } from '../../common/utils/browserUtils';
import {
  incrementEphemeralCounter,
  mapLoginErrorCodeToTranslationKey,
  getLoginErrorCodeFromCaptchaErrorCode,
  navigateToSecurityNotificationPage,
  getRedirectUrl,
  getCredentialType,
  mapErrorCodeToEphemeralEvent
} from '../utils/loginUtils';
import parseErrorCode from '../../common/utils/requestUtils';
import { parseCaptchaData, parseSecurityQuestionsData } from '../../common/utils/errorParsingUtils';

// components
import LoginForm from '../components/LoginForm';
import LoginCaptcha from '../components/LoginCaptcha';
import Login2sv from '../components/Login2sv';
import LoginAlternative from '../components/LoginAlternative';
import LoginIdVerification from '../components/LoginIdVerification';
import LoginSecurityQuestions from '../components/LoginSecurityQuestions';
import SignupLink from '../components/SignupLink';
import ForgotCredentialLink from '../components/ForgotCredentialLink';

export const LoginBase = ({ translate }: WithTranslationsProps): JSX.Element => {
  // captcha states
  const [unifiedCaptchaId, setUnifiedCaptchaId] = useState('');
  const [dataExchange, setDataExchange] = useState('');
  const [captchaId, setCaptchaId] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  // 2sv states
  const [userId, setUserId] = useState('');
  const [challengeId, setChallengeId] = useState('');
  const [failed2svChallengeCount, setFailed2svChallengeCount] = useState(0);

  // security questions state
  const [securityQuestionsSessionId, setSecurityQuestionsSessionId] = useState('');
  const [securityQuestionsRedemptionToken, setSecurityQuestionsRedemptionToken] = useState('');

  // form states
  const [credentialValue, setCredentialValue] = useState('');
  const [credentialType, setCredentialType] = useState(CredentialType.Username);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [useDefaultCredentialType, setUseDefaultCredentialType] = useState(false);

  // alternative login states
  const [authTokenCode, setAuthTokenCode] = useState('');
  const [authTokenPrivateKey, setAuthTokenPrivateKey] = useState('');

  let isFirstAttempt = true;

  // id verification states
  const [identityVerificationLoginTicket, setIdentityVerificationLoginTicket] = useState('');

  const shouldTriggerIdVerification = (
    result: TLoginResponse | TLoginWithVerificationTokenResponse
  ) => {
    return Boolean(result?.identityVerificationLoginTicket);
  };

  const triggerIdVerification = (result: TLoginResponse | TLoginWithVerificationTokenResponse) => {
    setIdentityVerificationLoginTicket(result.identityVerificationLoginTicket);
  };

  const navigatePostLogin = () => {
    setIsLoading(true);
    navigateToPage(getRedirectUrl());
  };

  const shouldTrigger2sv = (result: TLoginResponse): boolean => {
    return Boolean(result?.twoStepVerificationData?.ticket);
  };

  const trigger2sv = (result: TLoginResponse) => {
    const input2svParams = {
      userId: result.user.id.toString(),
      challengeId: result.twoStepVerificationData.ticket
    };
    setIsLoading(false);
    handle2svDataUpdated(input2svParams);
  };

  /*
    The 2sv handler upon triggering the challenge.
  */
  const handle2svDataUpdated = (data: T2svChallengeInputParams): void => {
    setUserId(data.userId);
    setChallengeId(data.challengeId);
  };

  /*
    The 2sv handler when the challenge is completed.
  */
  const handle2svChallengeCompleted = async (data: TOn2svChallengeCompletedData) => {
    try {
      const result: TLoginWithVerificationTokenResponse = await loginWithVerificationToken(userId, {
        challengeId,
        verificationToken: data.verificationToken,
        rememberDevice: data.rememberDevice
      });
      if (shouldTriggerIdVerification(result)) {
        triggerIdVerification(result);
      } else {
        navigatePostLogin();
      }
    } catch (error) {
      // pass down error to form
      // Note that this is not the same error when a user puts the wrong 2sv code.
      handleUnknownError();
    }
  };

  /*
    The 2sv handler when system errors are returned from the service
  */
  const handle2svChallengeInvalidated = (data: TOn2svChallengeInvalidatedData) => {
    setFailed2svChallengeCount(failed2svChallengeCount + 1);
    setErrorMsg(translate(FeatureLoginPage.ResponseVerificationError));
    if (failed2svChallengeCount < retryAttempts.maxInvalidated2svChallengeAttempts) {
      handleSubmit(false);
    }
  };

  /*
    The 2sv handler when the challenge is abandoned
  */
  const handle2svChallengeAbandoned = (data: unknown) => {
    setUserId('');
    setChallengeId('');
    clearCaptchaData();
    setIsLoading(false);
  };

  /*
    The captcha handler upon triggering the challenge
  */
  const handleCaptchaDataUpdated = (data: TCaptchaInputParams) => {
    setUnifiedCaptchaId(data.unifiedCaptchaId);
    setDataExchange(data.dataExchange);
  };

  /*
    The captcha handler when the challenge is completed
  */
  const handleCaptchaChallengeCompleted = (data: TOnCaptchaChallengeCompletedData) => {
    setCaptchaId(data.captchaId);
    setCaptchaToken(data.captchaToken);
  };

  /*
    The captcha handler when system errors are returned from the service
  */
  const handleCaptchaChallengeInvalidated = (data: TOnCaptchaChallengeInvalidatedData) => {
    const errorCode = getLoginErrorCodeFromCaptchaErrorCode(data.errorCode);
    const cType = getCredentialType(credentialValue);
    setErrorMsg(translate(mapLoginErrorCodeToTranslationKey(errorCode, cType)));
  };

  const handleCaptchaChallengeAbandoned = () => {
    clearCaptchaData();
    setIsLoading(false);
  };

  /*
    The security questions handler upon triggering the challenge
  */
  const handleSecurityQuestionsDataUpdated = (data: TSecurityQuestionsInputParam) => {
    setUserId(data.userId);
    setSecurityQuestionsSessionId(data.sessionId);
  };

  /*
    The security questions handler when the challenge is completed
  */
  const handleSecurityQuestionsChallengeCompleted = (
    data: TOnSecurityQuestionsChallengeCompletedData
  ) => {
    setSecurityQuestionsRedemptionToken(data.redemptionToken);
  };

  /*
    The security questions handler when system errors are returned from the service
  */
  const handleSecurityQuestionsChallengeInvalidated = (
    data: TOnSecurityQuestionsChallengeInvalidatedData
  ) => {
    setSecurityQuestionsSessionId('');
    setSecurityQuestionsRedemptionToken('');
    handleSubmit(false);
  };
  const handleSecurityQuestionsChallengeAbandoned = (data: unknown) => {
    setUserId('');
    setSecurityQuestionsSessionId('');
    clearCaptchaData();
    setIsLoading(false);
  };

  /*
    The login form handlers
  */
  const handleCredentialValueChange = (value: string) => {
    setErrorMsg('');
    setCredentialValue(value);
    setCredentialType(getCredentialType(value));
  };

  const handlePasswordChange = (value: string) => {
    setErrorMsg('');
    setPassword(value);
  };

  const handlePostLogin = (result: TLoginResponse) => {
    if (shouldTrigger2sv(result)) {
      trigger2sv(result);
    } else if (shouldTriggerIdVerification(result)) {
      // About the order of these checks, since identityVerificationLoginTicket
      // returns from 2sv endpoint as well, it should be checked after 2sv.
      triggerIdVerification(result);
    } else {
      navigatePostLogin();
    }
  };

  const clearCaptchaData = () => {
    setDataExchange('');
    setUnifiedCaptchaId('');
  };

  const handleLoginError = (error: unknown, cType: CredentialType) => {
    const errorCode = parseErrorCode(error);
    switch (errorCode) {
      case errorCodes.captcha:
        handleCaptchaError(error);
        return;
      case errorCodes.passwordResetRequired:
        handlePasswordResetRequiredError();
        return;
      case errorCodes.securityQuestionRequired:
        handleSecurityQuestionsRequiredError(error);
        return;
      case errorCodes.defaultLoginRequired:
        handleDefaultLoginRequired();
        return;
      default:
        clearCaptchaData();
        setIsLoading(false);
        incrementEphemeralCounter(mapErrorCodeToEphemeralEvent(errorCode));
        setErrorMsg(translate(mapLoginErrorCodeToTranslationKey(errorCode, cType)));
    }
  };

  /*
    trigger captcha challenge when login returns captcah error
  */
  const handleCaptchaError = (error: unknown) => {
    const captchaData: TCaptchaInputParams = parseCaptchaData(error);
    incrementEphemeralCounter(eventCounters.captcha);
    handleCaptchaDataUpdated(captchaData);
  };

  /*
    The security questions handler upon triggering the challenge
  */
  const handleSecurityQuestionsRequiredError = (error: unknown) => {
    const securityQuestionsData = parseSecurityQuestionsData(error);
    incrementEphemeralCounter(eventCounters.securityQuestionRequired);
    handleSecurityQuestionsDataUpdated(securityQuestionsData);
  };
  /*
    navigate to security notification page when password reset is required
  */
  const handlePasswordResetRequiredError = () => {
    incrementEphemeralCounter(eventCounters.passwordResetRequired);
    navigateToSecurityNotificationPage();
  };

  const handleDefaultLoginRequired = () => {
    clearCaptchaData();
    setUseDefaultCredentialType(true);
    incrementEphemeralCounter(eventCounters.defaultLoginRequired);
  };

  const handleUnknownError = () => {
    setErrorMsg(translate(FeatureLoginPage.MessageUnknownErrorTryAgain));
  };

  const handleCrossDeviceLoginCodeValidated = (data: TLoginWithAuthTokenParams) => {
    setIsLoading(true);
    setCredentialType(CredentialType.AuthToken);
    setAuthTokenCode(data.code);
    setAuthTokenPrivateKey(data.privateKey);
  };

  const buildLoginParams = (): TLoginParams => {
    let params: TLoginParams;

    if (credentialType === CredentialType.AuthToken) {
      params = {
        ctype: credentialType,
        cvalue: authTokenCode,
        password: authTokenPrivateKey
      };
    } else {
      params = {
        ctype: useDefaultCredentialType ? CredentialType.Username : credentialType,
        cvalue: credentialValue,
        password
      };
    }

    // attach captcha data only they exist
    if (captchaId && captchaToken) {
      params.captchaId = captchaId;
      params.captchaToken = captchaToken;
    }
    // attach security questions data if they exist
    if (securityQuestionsSessionId && securityQuestionsRedemptionToken) {
      params.securityQuestionSessionId = securityQuestionsSessionId;
      params.securityQuestionRedemptionToken = securityQuestionsRedemptionToken;

      // These parameters should only be used once, so we reset them after
      // copying them over to the parameters.
      setSecurityQuestionsSessionId('');
      setSecurityQuestionsRedemptionToken('');
    }
    return params;
  };

  const loginWithParams = async (params: TLoginParams) => {
    try {
      const result: TLoginResponse = await login(params);
      incrementEphemeralCounter(eventCounters.success);
      handlePostLogin(result);
    } catch (error) {
      handleLoginError(error, params.ctype);
    }
  };

  /*
  This function is flexible enough to handle the following cases.
     1. login with username/email/phone after login button is clicked.
     2. login with username/email/phone after captcha passes.
     3. login with username/email/phone after security questions pass.
     4. login with auth token after the token is validated.
     5. login with auth token after captha passes.
     6. login with username after use default login error is thrown for all number usernames.
  */
  const handleSubmit = (isFromLoginButtonClick = true) => {
    const params = buildLoginParams();
    if (isFromLoginButtonClick) {
      if (!credentialValue || !password) {
        setErrorMsg(translate(FeatureLoginPage.MessageUsernameAndPasswordRequired));
        return;
      }
      sendLoginButtonClickEvent();
      incrementEphemeralCounter(eventCounters.attempt);
      if (isFirstAttempt) {
        incrementEphemeralCounter(eventCounters.firstAttempt);
        isFirstAttempt = false;
      }
    }
    setIsLoading(true);
    // eslint-disable-next-line no-void
    void loginWithParams(params);
  };

  // Determine whether a new submit is required because Security Questions
  // parameters have been populated.
  useEffect(() => {
    if (securityQuestionsSessionId && securityQuestionsRedemptionToken) {
      handleSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityQuestionsSessionId && securityQuestionsRedemptionToken]);

  useEffect(() => {
    // use auth token login only after having auth token private key and code,
    // handleSubmit will handle captcha or security questions if needed.
    if (authTokenPrivateKey && authTokenCode) {
      handleSubmit(false);
    }
  }, [authTokenPrivateKey, authTokenCode]);

  useEffect(() => {
    if (useDefaultCredentialType) {
      handleSubmit(false);
    }
  }, [useDefaultCredentialType]);

  return (
    <div id='login-base'>
      <div className='section-content login-section'>
        <h2 className='login-header'>{translate(FeatureLoginPage.HeadingLoginRoblox)}</h2>
        <LoginForm
          captchaId={captchaId}
          captchaToken={captchaToken}
          credentialValue={credentialValue}
          password={password}
          isLoading={isLoading}
          errorMsg={errorMsg}
          translate={translate}
          onFormSubmit={handleSubmit}
          onCredentialValueChange={handleCredentialValueChange}
          onPasswordChange={handlePasswordChange}
        />
        <ForgotCredentialLink />
        <LoginAlternative
          onCrossDeviceLoginCodeValidated={handleCrossDeviceLoginCodeValidated}
          translate={translate}
        />
        <SignupLink />
      </div>
      {unifiedCaptchaId && dataExchange && (
        <LoginCaptcha
          unifiedCaptchaId={unifiedCaptchaId}
          dataExchange={dataExchange}
          onCaptchaChallengeCompleted={handleCaptchaChallengeCompleted}
          onCaptchaChallengeInvalidated={handleCaptchaChallengeInvalidated}
          onCaptchaChallengeAbandoned={handleCaptchaChallengeAbandoned}
          onUnknownError={handleUnknownError}
        />
      )}
      {userId && securityQuestionsSessionId && (
        <LoginSecurityQuestions
          userId={userId}
          sessionId={securityQuestionsSessionId}
          onSecurityQuestionsChallengeCompleted={handleSecurityQuestionsChallengeCompleted}
          onSecurityQuestionsChallengeInvalidated={handleSecurityQuestionsChallengeInvalidated}
          onSecurityQuestionsChallengeAbandoned={handleSecurityQuestionsChallengeAbandoned}
          onUnknownError={handleUnknownError}
        />
      )}
      {userId && challengeId && (
        <Login2sv
          userId={userId}
          challengeId={challengeId}
          on2svChallengeCompleted={handle2svChallengeCompleted}
          on2svChallengeInvalidated={handle2svChallengeInvalidated}
          on2svChallengeAbandoned={handle2svChallengeAbandoned}
          onUnknownError={handleUnknownError}
        />
      )}
      <LoginIdVerification
        identityVerificationLoginTicket={identityVerificationLoginTicket}
        translate={translate}
      />
      <div id='crossDeviceLoginDisplayCodeModal-container' />
    </div>
  );
};

export default withTranslations(LoginBase, loginTranslationConfig);
