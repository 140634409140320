/**
 * Constants for event stream events in auth webapp.
 */
const EVENT_CONSTANTS = {
  eventName: {
    loginOtherDevice: 'loginOtherDevice'
  },
  context: {
    loginPage: 'loginPage',
    loginForm: 'LoginForm'
  },
  aType: {
    click: 'click',
    offFocus: 'offFocus',
    focus: 'focus'
  },
  field: {
    loginOtherDevice: 'loginOtherDevice',
    loginSubmitButtonName: 'loginSubmit',
    password: 'password',
    username: 'username'
  }
} as const;

export default EVENT_CONSTANTS;
