import { eventStreamService } from 'core-roblox-utilities';
import EVENT_CONSTANTS from '../../common/constants/eventsConstants';

const { eventTypes } = eventStreamService;

// Send cross device login button click event
export const sendXdlButtonClickEvent = (): void => {
  eventStreamService.sendEventWithTarget(
    eventTypes.formInteraction,
    EVENT_CONSTANTS.context.loginPage,
    {
      field: EVENT_CONSTANTS.field.loginOtherDevice,
      aType: EVENT_CONSTANTS.aType.click
    }
  );
};

export const sendLoginButtonClickEvent = (): void => {
  eventStreamService.sendEventWithTarget(
    eventTypes.formInteraction,
    EVENT_CONSTANTS.context.loginPage,
    {
      field: EVENT_CONSTANTS.field.loginSubmitButtonName,
      aType: EVENT_CONSTANTS.aType.click
    }
  );
};

type TLoginFormInteractionEventInput = {
  field: string;
  aType: string;
};

const sendLoginFormInteractionEvent = (param: TLoginFormInteractionEventInput) => {
  eventStreamService.sendEventWithTarget(
    eventTypes.formInteraction,
    EVENT_CONSTANTS.context.loginForm,
    {
      field: param.field,
      aType: param.aType
    }
  );
};

export const sendUsernameFocusEvent = (): void => {
  sendLoginFormInteractionEvent({
    field: EVENT_CONSTANTS.field.username,
    aType: EVENT_CONSTANTS.aType.focus
  });
};

export const sendUsernameOffFocusEvent = (): void => {
  sendLoginFormInteractionEvent({
    field: EVENT_CONSTANTS.field.username,
    aType: EVENT_CONSTANTS.aType.offFocus
  });
};

export const sendPasswordFocusEvent = (): void => {
  sendLoginFormInteractionEvent({
    field: EVENT_CONSTANTS.field.password,
    aType: EVENT_CONSTANTS.aType.focus
  });
};

export const sendPasswordOffFocusEvent = (): void => {
  sendLoginFormInteractionEvent({
    field: EVENT_CONSTANTS.field.password,
    aType: EVENT_CONSTANTS.aType.offFocus
  });
};
