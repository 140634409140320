import { CaptchaConstants } from 'Roblox';
import { urlService } from 'core-utilities';
import {
  errorCodes,
  urlConstants,
  counterConstants,
  eventCounters,
  isNewLoginQueryString
} from '../constants/loginConstants';
import { navigateToPage } from '../../common/utils/browserUtils';
import RobloxEventTracker from '../../common/eventTracker';
import { FeatureLoginPage } from '../../common/constants/translationConstants';
import { CredentialType } from '../../common/types/loginTypes';
import { reactLoginPageContainer } from '../../common/constants/browserConstants';
import { isValidEmail, isPhoneNumber } from '../../common/utils/formUtils';
import urlQueryConstants from '../../common/constants/urlConstants';

// Mapping from captchaErrorCode to loginErrorCode
export const getLoginErrorCodeFromCaptchaErrorCode = (captchaErrorCode: number): number => {
  const { failedToLoadProviderScript, failedToVerify } = CaptchaConstants.errorCodes;
  const { captchaLoadFailed, captchaVerifyFailed, captchaUnknownError } = errorCodes;
  switch (captchaErrorCode) {
    case failedToLoadProviderScript:
      return captchaLoadFailed;
    case failedToVerify:
      return captchaVerifyFailed;
    default:
      return captchaUnknownError;
  }
};

// Navigate to security notification page when login endpoint returns passwordResetRequired.
export const navigateToSecurityNotificationPage = (): void => {
  navigateToPage(urlConstants.securityNotification);
};

// EphemeralCounter
export const incrementEphemeralCounter = (eventName: string): void => {
  if (RobloxEventTracker && eventName) {
    RobloxEventTracker.fireEvent(counterConstants.prefix + eventName);
  }
};

export const mapErrorCodeToEphemeralEvent = (errorCode: number | null) => {
  let eventName = eventCounters.unknownError;
  switch (errorCode) {
    case errorCodes.badCredentials:
      eventName = eventCounters.invalidCredentials;
      break;
    case errorCodes.accountNotFound:
      eventName = eventCounters.accountNotFound;
      break;
    case errorCodes.accountIssue:
      eventName = eventCounters.accountIssue;
      break;
    case errorCodes.tooManyAttempts:
      eventName = eventCounters.tooManyAttempts;
      break;
    case errorCodes.defaultLoginRequired:
      eventName = eventCounters.defaultLoginRequired;
      break;
    case errorCodes.unverifiedCredentials:
      eventName = eventCounters.unverifiedAccount;
      break;
    case errorCodes.captchaLoadFailed:
      eventName = eventCounters.captchaLoadFailed;
      break;
    case errorCodes.captchaVerifyFailed:
      eventName = eventCounters.captchaVerifyFailed;
      break;
    case errorCodes.luoBuUserDenied:
      eventName = eventCounters.luoBuUserDenied;
      break;
    case errorCodes.captchaUnknownError:
      eventName = eventCounters.captchaUnknownError;
      break;
    case errorCodes.screentimeRestricted:
      eventName = eventCounters.screentimeRestricted;
      break;
    default:
      eventName = eventCounters.unknownError;
      break;
  }
  return eventName;
};

const getCredentialErrorMessage = (errorCode: number, ctype?: CredentialType): string => {
  switch (errorCode) {
    case errorCodes.badCredentials:
      if (ctype === CredentialType.Email) {
        return FeatureLoginPage.ResponseIncorrectEmailOrPassword;
      }
      if (ctype === CredentialType.PhoneNumber) {
        return FeatureLoginPage.ResponseIncorrectPhoneOrPassword;
      }
      return FeatureLoginPage.ResponseIncorrectUsernamePassword;

    case errorCodes.defaultLoginRequired:
      if (ctype === CredentialType.Email) {
        return FeatureLoginPage.ResponseEmailLinkedToMultipleAccountsLoginWithUsername;
      }
      if (ctype === CredentialType.PhoneNumber) {
        return FeatureLoginPage.ResponseLoginWithUsername;
      }
      return '';

    case errorCodes.unverifiedCredentials:
      if (ctype === CredentialType.Email) {
        return FeatureLoginPage.ResponseUnverifiedEmailLoginWithUsername;
      }
      if (ctype === CredentialType.PhoneNumber) {
        return FeatureLoginPage.ResponseUnverifiedPhoneLoginWithUsername;
      }
      return '';

    default:
      return '';
  }
};

export const mapLoginErrorCodeToTranslationKey = (
  errorCode: number | null,
  ctype?: CredentialType
): string => {
  switch (errorCode) {
    case errorCodes.defaultLoginRequired:
    case errorCodes.unverifiedCredentials:
    case errorCodes.badCredentials:
      return getCredentialErrorMessage(errorCode, ctype);
    case errorCodes.accountNotFound:
      return FeatureLoginPage.ResponseAccountNotFound;
    case errorCodes.accountIssue:
      return FeatureLoginPage.ResponseAccountIssueErrorContactSupport;
    case errorCodes.tooManyAttempts:
      return FeatureLoginPage.ResponseTooManyAttemptsPleaseWait;
    case errorCodes.captchaLoadFailed:
      return FeatureLoginPage.ResponseCaptchaErrorFailedToLoad;
    case errorCodes.captchaVerifyFailed:
      return FeatureLoginPage.ResponseCaptchaErrorFailedToVerify;
    case errorCodes.luoBuUserDenied:
      return FeatureLoginPage.ResponseGlobalAppAccessError;
    case errorCodes.captchaUnknownError:
      return FeatureLoginPage.MessageUnknownErrorTryAgain;
    case errorCodes.screentimeRestricted:
      return FeatureLoginPage.DescriptionCurfewMessage;
    default:
      return FeatureLoginPage.MessageUnknownErrorTryAgain;
  }
};

export const getCredentialType = (credentialValue: string): CredentialType => {
  if (isValidEmail(credentialValue)) {
    return CredentialType.Email;
  }
  if (isPhoneNumber(credentialValue)) {
    return CredentialType.PhoneNumber;
  }
  return CredentialType.Username;
};

export const getReturnUrl = (): string => {
  const entryPoint = reactLoginPageContainer();
  const returnUrl = entryPoint?.getAttribute('data-return-url') || '';
  return returnUrl;
};

const addNewLoginQueryStringToUrl = (url: string) => {
  if (!url) {
    return '/';
  }

  let result = url;
  if (url.indexOf(urlQueryConstants.urlQueryStringPrefix) > -1) {
    result += urlQueryConstants.urlQueryParameterSeparator;
  } else {
    result += urlQueryConstants.urlQueryStringPrefix;
  }

  result += isNewLoginQueryString;
  return result;
};

export const getRedirectUrl = () => {
  const url = addNewLoginQueryStringToUrl(getReturnUrl());
  return urlService.getAbsoluteUrl(url);
};

export const getIsReactUIEnabled = (): boolean => {
  const entryPoint = reactLoginPageContainer();
  const isReactUIEnabled = entryPoint?.getAttribute('data-enable-react-ui') === 'true';
  return isReactUIEnabled;
};
