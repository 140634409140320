// this file will be deleted after the migration.

import React, { useEffect } from 'react';
import { AccountIntegrityChallengeService } from 'Roblox';
import {
  T2svChallengeRenderEvent,
  T2svChallengeInputParameters
} from '../../../common/types/twoStepVerificationTypes';
import { containerConstants } from '../../constants/loginConstants';

const { TwoStepVerification } = AccountIntegrityChallengeService;

export const Login2sv = (): JSX.Element => {
  const { react2svContainer } = containerConstants;
  const handle2svChallenge = (inputParams: T2svChallengeInputParameters) => {
    const {
      userId,
      challengeId,
      onChallengeCompleted,
      onChallengeInvalidated,
      onModalChallengeAbandoned,
      onUnknownError
    } = inputParams;
    // eslint-disable-next-line no-void
    void TwoStepVerification.renderChallenge({
      containerId: react2svContainer,
      userId,
      challengeId,
      actionType: TwoStepVerification.ActionType.Login,
      renderInline: false,
      shouldShowRememberDeviceCheckbox: true,
      shouldDynamicallyLoadTranslationResources: false,
      onChallengeCompleted,
      onChallengeInvalidated,
      onModalChallengeAbandoned
    })
      .then(success => {
        if (!success) {
          onUnknownError();
        }
      })
      .catch(() => {
        onUnknownError();
      });
  };

  const onRender2sv = (event: T2svChallengeRenderEvent) => {
    if (event.detail) {
      const { inputParams } = event.detail;
      handle2svChallenge(inputParams);
    }
  };

  useEffect(() => {
    window.addEventListener('render2svChallenge', onRender2sv as EventListener);
    return () => {
      window.removeEventListener('render2svChallenge', onRender2sv as EventListener);
    };
  }, []);

  return <div id={react2svContainer} />;
};

export default Login2sv;
