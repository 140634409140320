// this file will be deleted after the migration.
// TODO: https://jira.rbx.com/browse/AA-316
import React from 'react';
import { withTranslations } from 'react-utilities';
import Login2sv from '../components/hybrid/Login2sv';
import LoginCaptcha from '../components/hybrid/LoginCaptcha';
import LoginSecurityQuestions from '../components/hybrid/LoginSecurityQuestions';
import { loginTranslationConfig } from '../translation.config';

export const LoginBaseHybrid = (): JSX.Element => {
  return (
    <div>
      <LoginCaptcha />
      <Login2sv />
      <LoginSecurityQuestions />
    </div>
  );
};

export default withTranslations(LoginBaseHybrid, loginTranslationConfig);
