import React, { useEffect } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { CrossDeviceLoginDisplayCodeService } from 'Roblox';
import { FeatureLoginPage } from '../../common/constants/translationConstants';
import { loginTranslationConfig } from '../translation.config';
import {
  TLoginWithAuthTokenRenderEvent,
  TLoginWithAuthTokenParams
} from '../../common/types/crossDeviceLoginTypes';
import { sendXdlButtonClickEvent } from '../services/eventService';

export const LoginAlternative = ({
  onCrossDeviceLoginCodeValidated,
  translate
}: {
  onCrossDeviceLoginCodeValidated: (data: TLoginWithAuthTokenParams) => void;
  translate: WithTranslationsProps['translate'];
}): JSX.Element => {
  const handleCrossDeviceLoginClick = () => {
    sendXdlButtonClickEvent();
    CrossDeviceLoginDisplayCodeService.openModal();
  };

  useEffect(() => {
    const onCrossDeviceCodeValidated = (event: TLoginWithAuthTokenRenderEvent) => {
      if (event.detail) {
        onCrossDeviceLoginCodeValidated(event.detail);
      }
    };

    window.addEventListener(
      'OnCrossDeviceCodeValidated',
      onCrossDeviceCodeValidated as EventListener
    );
    return () => {
      window.removeEventListener(
        'OnCrossDeviceCodeValidated',
        onCrossDeviceCodeValidated as EventListener
      );
    };
  }, []);

  return (
    <div>
      <div className='alternative-login-divider-container'>
        <div className='rbx-divider alternative-login-divider' />
        <div className='divider-text-container'>
          <span className='divider-text xsmall'>
            {translate(FeatureLoginPage.LabelLoginWithYour)}
          </span>
        </div>
      </div>
      <button
        type='button'
        id='cross-device-login-button'
        className='btn-full-width btn-control-md cross-device-login-button'
        onClick={handleCrossDeviceLoginClick}>
        <span>{translate(FeatureLoginPage.ActionAnotherLoggedInDevice)}</span>
      </button>
    </div>
  );
};

export default withTranslations(LoginAlternative, loginTranslationConfig);
