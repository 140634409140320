import React from 'react';
import { render } from 'react-dom';
import { ready } from 'core-utilities';
import Roblox from 'Roblox';
import LoginBase from './containers/LoginBase';
import ReactLoginService from './services/loginService';
import ReactIdentityVerificationService from './services/identityVerificationService';
import { reactLoginPageContainer } from '../common/constants/browserConstants';
import '../../../css/login/login.scss';

import LoginBaseHybrid from './containers/LoginBaseHybrid';

/* As of now, reactLogin supports two versions of login implementation.
  1. angular-react hybrid version with no form UI written in react. 
  2. react version with form UI written in react
  It is depending on a data-attr of data-enable-react-ui to decide which version to show,
  and it's controlled by a setting. Each version is organized in different containers for
  easier clean up in the future.
*/

import { getIsReactUIEnabled } from './utils/loginUtils';

Object.assign(Roblox, {
  ReactLoginService
});

Object.assign(Roblox, {
  ReactIdentityVerificationService
});

function renderApp() {
  const entryPoint = reactLoginPageContainer();
  if (entryPoint) {
    if (getIsReactUIEnabled()) {
      render(<LoginBase />, entryPoint);
    } else {
      render(<LoginBaseHybrid />, entryPoint);
    }
  } else {
    // Recursively call renderApp if target div not found
    // Callback will be triggered before every repaint
    window.requestAnimationFrame(renderApp);
  }
}

ready(() => {
  renderApp();
});
