import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { loginTranslationConfig } from '../translation.config';
import { FeatureLoginPage } from '../../common/constants/translationConstants';
import { buildSignupRedirUrl } from '../../common/utils/browserUtils';

export const SignupLink = ({ translate }: WithTranslationsProps): JSX.Element => {
  const signupRedirUrl = buildSignupRedirUrl();
  return (
    <div className='text-center'>
      <div className='signup-option'>
        <span className='no-account-text'>{translate(FeatureLoginPage.LabelNoAccount)}</span>
        <a id='sign-up-link' className='text-link signup-link' href={signupRedirUrl} target='_self'>
          {translate(FeatureLoginPage.ActionSignUpCapitalized)}
        </a>
      </div>
    </div>
  );
};

export default withTranslations(SignupLink, loginTranslationConfig);
