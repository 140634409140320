// this file will be deleted after the migration.
import React, { useEffect } from 'react';
import { AccountIntegrityChallengeService } from 'Roblox';
import { containerConstants } from '../../constants/loginConstants';
import {
  TSecurityQuestionsInputParameters,
  TSecurityQuestionsRenderEvent
} from '../../../common/types/securityQuestionsTypes';

const { SecurityQuestions } = AccountIntegrityChallengeService;

export const LoginSecurityQuestions = (): JSX.Element => {
  const { reactSecurityQuestionsContainer } = containerConstants;

  useEffect(() => {
    const handleSecurityQuestionsChallenge = (inputParams: TSecurityQuestionsInputParameters) => {
      const {
        userId,
        sessionId,
        onChallengeCompleted,
        onChallengeInvalidated,
        onModalChallengeAbandoned,
        onUnknownError
      } = inputParams;
      // eslint-disable-next-line no-void
      void SecurityQuestions.renderChallenge({
        containerId: reactSecurityQuestionsContainer,
        userId,
        sessionId,
        renderInline: false,
        shouldDynamicallyLoadTranslationResources: false,
        onChallengeCompleted,
        onChallengeInvalidated,
        onModalChallengeAbandoned
      })
        .then(success => {
          if (!success) {
            onUnknownError();
          }
        })
        .catch(() => {
          onUnknownError();
        });
    };

    const onRenderSecurityQuestions = (event: TSecurityQuestionsRenderEvent) => {
      if (event.detail) {
        const { inputParams } = event.detail;
        handleSecurityQuestionsChallenge(inputParams);
      }
    };

    window.addEventListener(
      'renderSecurityQuestionsChallenge',
      onRenderSecurityQuestions as EventListener
    );
    return () => {
      window.removeEventListener(
        'renderSecurityQuestionsChallenge',
        onRenderSecurityQuestions as EventListener
      );
    };
  }, [reactSecurityQuestionsContainer]);

  return <div id={reactSecurityQuestionsContainer} />;
};

export default LoginSecurityQuestions;
