import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { loginTranslationConfig } from '../translation.config';
import { FeatureLoginPage } from '../../common/constants/translationConstants';
import { urlConstants } from '../constants/loginConstants';

export const ForgotCredentialLink = ({ translate }: WithTranslationsProps): JSX.Element => {
  const { forgotCredentialsUrl } = urlConstants;
  return (
    <div className='text-center forgot-credentials-link'>
      <a
        id='forgot-credentials-link'
        className='text-link'
        href={forgotCredentialsUrl}
        target='_self'>
        {translate(FeatureLoginPage.ActionForgotPasswordOrUsernameQuestionCapitalized)}
      </a>
    </div>
  );
};

export default withTranslations(ForgotCredentialLink, loginTranslationConfig);
